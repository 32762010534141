import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  connect () {
    this._ticking = false
    // Pre-bound functions to allow adding/removing on listeners
    this._boundHandleScroll = this.handleScroll.bind(this)
    this._boundCheckForCookieConsent = this.checkForCookieConsent.bind(this)
    // Only enable if cookie consent has been done (or
    // if we hear the cookie consent event later)
    if (this.isCookieConsentApproved()) {
      this.addScrollListener()
    } else {
      this.addCookieConsentListener()
    }

    // Triggering scroll even here so we can show popup right after Turbo updates it
    document.dispatchEvent(new CustomEvent('scroll'))
  }

  addCookieConsentListener () {
    document.addEventListener('turbo:before-fetch-response', this._boundCheckForCookieConsent)
  }

  disconnect () {
    this.removeScrollListener()
  }

  addScrollListener () {
    document.addEventListener('scroll', this._boundHandleScroll, { passive: true })
  }

  addScrollListenerFromCookieConsent () {
    this.removeCookieConsentListener()
    this.data.set('cookieConsent', 'true') // As would be set if cookie consent had previous been completed
    this.addScrollListener()
  }

  checkForCookieConsent (event) {
    const consents = event.detail.fetchResponse.response.headers.get('x-cookie-consents')

    if (consents === undefined || consents === null || !consents.includes('functional')) {
      return
    }

    this.addScrollListenerFromCookieConsent()
  }

  checkScrollPosition (lastKnownScrollPosition) {
    if ((lastKnownScrollPosition / this.documentHeight()) > this.scrollThreshold()) {
      this.showSignUpUI()
    }
  }

  documentHeight () {
    if (this._documentHeight === undefined) {
      this._documentHeight = document.documentElement.scrollHeight
    }
    return this._documentHeight
  }

  handleScroll (e) {
    if (!this.isCookieConsentApproved()) {
      return
    }

    if (!this._ticking) {
      clearTimeout(this._scrollCheckTimeout)

      this._scrollCheckTimeout = setTimeout(() => {
        window.requestAnimationFrame(() => {
          this.checkScrollPosition(document.documentElement.scrollTop)
          this._ticking = false
        })
      }, 200)

      this._ticking = true
    }
  }

  removeCookieConsentListener () {
    document.removeEventListener('turbo:before-fetch-response', this._boundCheckForCookieConsent)
  }

  removeScrollListener () {
    document.removeEventListener('scroll', this._boundHandleScroll)
  }

  scrollThreshold () {
    if (this._scrollThreshold === undefined) {
      // We'll show after 25% scroll by default (unless overridden)
      const desktopThreshold = this.data.get('dThreshold') !== null ? this.data.get('dThreshold') : 0.25
      const mobileThreshold = this.data.get('mThreshold') !== null ? this.data.get('mThreshold') : 0.25
      this._scrollThreshold = document.documentElement.clientWidth < 768 ? mobileThreshold : desktopThreshold
    }
    return this._scrollThreshold
  }

  showSignUpUI (lastKnownScrollPosition) {
    this.removeScrollListener()
    this.element.classList.add('newsletter-popup--open')
  }

  isCookieConsentApproved () {
    return document.cookie.includes('cookie_consent_functional=true')
  }
}
